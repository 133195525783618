import React, { useState, useEffect } from "react";
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer, ReferenceLine
} from 'recharts';
import "./EventPage.css";

// EventPage Component
export const EventPage = ({ onBackClick, eventId }) => {
  const [eventData, setEventData] = useState(null);  // State to hold event data
  const [loading, setLoading] = useState(true);  // Loading state
  const [marketData, setMarketData] = useState({});
  const [selectedTeamId, setSelectedTeamId] = useState(null);  // State to hold selected team_id
  const [selectedMarket, setSelectedMarket] = useState(null);  // State for selected event type
  const [selectedGames, setSelectedGames] = useState(10);  // Default number of games
  const [summarizedEvents, setSummarizedEvents] = useState([]);  // State to hold summarized events
  const [processedData, setProcessedData] = useState([]);  // Data to display on the graph
  const [availableDurations, setAvailableDurations] = useState([]);  // New state for available durations
  const [selectedDuration, setSelectedDuration] = useState('Full Game');  // State for selected duration
  const [availableLines, setAvailableLines] = useState([]);  // State to hold available lines
  const [selectedLine, setSelectedLine] = useState(null);  // State for the selected line
  const [lineValue, setLineValue] = useState(null); // State for global access to lineValue
  const [bookmakerOdds, setBookmakerOdds] = useState({});  // State to hold bookmaker odds
  const [oddsData, setOddsData] = useState({}); // Store the full odds response

  // Access the API token from the environment variable
  const API_TOKEN = process.env.REACT_APP_STATICA_API_TOKEN;

  // Handle market selection
  const handleMarketSelection = (marketName) => {
    const market = marketData[marketName];
    if (market) {
      setSelectedMarket(marketName);

      // Update available durations for the selected market
      if (market.durations?.length > 0) {
        setAvailableDurations(market.durations);

        // If current duration isn't supported, switch to first available
        if (!market.durations.includes(selectedDuration)) {
          setSelectedDuration(market.durations[0]);
        }
      } else {
        setAvailableDurations([]);
        setSelectedDuration(null);
      }

      // Reset line selection
      setSelectedLine(null);
      setLineValue(null);
    }
  };

  // Fetch event data
  useEffect(() => {
    const fetchEventData = async () => {
      try {
        const response = await fetch(`/api/get_event_info/${eventId}?user_id=12345678`, {
          headers: {
            'Authorization': `Bearer ${API_TOKEN}`
          }
        });

        if (!response.ok) {
          throw new Error(`Error fetching event data: ${response.status}`);
        }

        const data = await response.json();
        setEventData(data);
        setSelectedTeamId(data.home_id); // Set default selected team
      } catch (error) {
        console.error("Error fetching event data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEventData();
  }, [eventId, API_TOKEN]);

  // Fetch betting markets with metadata
  useEffect(() => {
    const fetchBettingMarkets = async () => {
      if (eventData?.sport_id) {
        try {
          const response = await fetch(`/api/get_betting_markets/${eventData.sport_id}`, {
            headers: {
              'Authorization': `Bearer ${API_TOKEN}`
            }
          });

          if (!response.ok) {
            throw new Error(`Error fetching betting markets: ${response.status}`);
          }

          const data = await response.json();
          setMarketData(data);

          // Set default market if none is selected
          if (!selectedMarket) {
            const firstMarketName = Object.keys(data)[0];
            if (firstMarketName) {
              setSelectedMarket(firstMarketName);
              const firstMarket = data[firstMarketName];
              if (firstMarket?.durations?.length > 0) {
                setSelectedDuration(firstMarket.durations[0]);
                setAvailableDurations(firstMarket.durations);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching betting markets:", error);
        }
      }
    };

    fetchBettingMarkets();
  }, [eventData?.sport_id, selectedMarket, API_TOKEN]);


   // Fetch summarized events when selectedTeamId changes
  useEffect(() => {
    const fetchSummarizedEvents = async () => {
      if (selectedTeamId) {
        try {
          const response = await fetch(`/api/get_team_stats/${selectedTeamId}`, {
            headers: {
              'Authorization': `Bearer ${API_TOKEN}`
            }
          });
          if (!response.ok) {
            throw new Error(`Error fetching summarized events: ${response.status}`);
          }
          const data = await response.json();
          setSummarizedEvents(data);  // Store the summarized events
        } catch (error) {
          console.error("Error fetching summarized events:", error);
        }
      }
    };

    fetchSummarizedEvents();
  }, [selectedTeamId, API_TOKEN]);


  // Fetch odds for all markets
  useEffect(() => {
    const fetchLinesAndOdds = async () => {
      try {
        const response = await fetch(`/api/get_lines_and_odds/${eventId}?best_only=False`, {
          headers: { 'Authorization': `Bearer ${API_TOKEN}` }
        });
        if (!response.ok) throw new Error(`Error fetching odds: ${response.status}`);
        const data = await response.json();
        setOddsData(data);
      } catch (error) {
        console.error("Error fetching odds:", error);
      }
    };
    fetchLinesAndOdds();
  }, [eventId, API_TOKEN]);


  // Helper function to check if marketData['Both'] is effectively empty
  const isEffectivelyEmpty = (data) => {
    return (
        !data || // Check if null or undefined
        (Array.isArray(data.lines) && data.lines.length === 0 && // Check if lines array is empty
            Object.keys(data.odds?.best || {}).length === 0) // Check if odds.best is empty
    );
  };

  // Update lines and odds based on user selections
  useEffect(() => {
    if (selectedMarket && selectedDuration) {
      const marketData = oddsData[selectedMarket]?.[selectedDuration];
      if (!marketData) {
        setAvailableLines([]);
        setBookmakerOdds({});
        return;
      }

      // Determine team scope
      const teamScope = (() => {
        if (!isEffectivelyEmpty(marketData['Both'])) {
          return marketData['Both'];
        }

        const isHomeSelected = selectedTeamId === eventData?.home_id;
        console.log("Selected Team:", isHomeSelected ? "Home" : "Away");

        const teamKey = isHomeSelected ? 'Home' : 'Away';
        const teamData = marketData[teamKey];

        if (teamData) {
          return teamData;
        }

        console.warn("No data found for 'Both', 'Home', or 'Away'. Defaulting to empty.");
        return {};
      })();

      // Set available lines, bookmaker odds, and selected line
      if (teamScope) {
        setAvailableLines(teamScope.lines || []);
        setBookmakerOdds(teamScope.odds || {});

        if (teamScope.lines?.length > 0) {
          setSelectedLine(teamScope.lines[0]);
          const [, value] = teamScope.lines[0].split(' ');
          setLineValue(value ? parseFloat(value) : null);
        } else {
          setSelectedLine(null);
          setLineValue(null);
        }
      } else {
        setAvailableLines([]);
        setBookmakerOdds({});
        setSelectedLine(null);
        setLineValue(null);
      }
    }
  }, [oddsData, selectedMarket, selectedDuration, selectedTeamId, eventData]);


  // Handle line selection
  // Modify the handleLineSelection function
  const handleLineSelection = async (line) => {
    // First set the selected line
    setSelectedLine(line);

    // Parse and set the line value
    if (line) {
      let value;
      if (selectedMarket === 'Handicap') {
        // For handicap, the line is the value itself
        value = parseFloat(line);
        setLineValue(-value); // Handicap logic is flipped
      } else {
        // For other markets, split the line into condition and value
        const [condition, lineValue] = line.split(' ');
        value = lineValue ? parseFloat(lineValue) : null;
        setLineValue(value);
      }
    } else {
      setLineValue(null);
    }
  };


  // Map available lines for display without needing to parse team-specific info
  const getFilteredLines = () => {
    return availableLines.map(line => {
      const [type, value] = line.split(' ');
      return value ? `${type} ${value}` : type; // Display type and value if both exist, else just type
    });
  };


  // Process data for graph
  useEffect(() => {
    if (summarizedEvents.length > 0 && selectedMarket && selectedGames && selectedDuration) {
      const market = marketData[selectedMarket];
      if (!market) return;

      const statsField = market.stats_fields[selectedDuration];
      const marketType = market.market_type;

      const gamesToAnalyze = summarizedEvents.slice(0, selectedGames);

      const dataForGraph = gamesToAnalyze.map(game => {
        let baseData = {
          event_time: game.event_time,
          opponent_name: game.opponent_name,
        };

        const statValue = game[statsField];
        let barValue = statValue;
        let isSuccess = false;
        let condition = null;

        if (selectedLine) {
          [condition] = selectedLine.split(' ');
          if (lineValue !== null || condition !== null){
            switch (marketType) {
              case 'over_under':
                isSuccess = condition === 'OVER' ? statValue > lineValue : statValue < lineValue;
                break;

              case 'binary':
                if (statsField.includes('result')) {
                  isSuccess = (selectedLine === 'P1' && statValue === 'Win') ||
                      (selectedLine === 'PX' && statValue === 'Draw') ||
                      (selectedLine === 'P2' && statValue === 'Loss') ||
                      (selectedLine === '1X' && ['Win', 'Draw'].includes(statValue)) ||
                      (selectedLine === 'X2' && ['Draw', 'Loss'].includes(statValue));
                  barValue = isSuccess ? 1 : 0;
                } else {
                  isSuccess = (condition === 'YES' && statValue === true) || (condition === 'NO' && statValue === false);
                  barValue = isSuccess ? 1 : 0;
                }
                break;

              case 'handicap':
                barValue = statValue;
                isSuccess = statValue + lineValue > 0;
                break;
            }
          }
          }

        return {
          ...baseData,
          value: barValue,
          // Use neutral color if no line selected, otherwise show success/failure
          fill: selectedLine && (lineValue !== null || condition !== null) ? (isSuccess ? "#4CAF50" : "#F44336") : "#2196F3",
          isSuccess: isSuccess
        };
      });

      setProcessedData(dataForGraph.reverse());
    }
  }, [summarizedEvents, selectedMarket, selectedGames, selectedDuration, selectedLine, lineValue, marketData]);

  // Function to handle adding a bet to the user's slip
  const addBetToSlip = async () => {
    if (!selectedLine || !bookmakerOdds.best[selectedLine]) {
      console.error("No selected line or 'best' odds available for this line.");
      return;
    }

    const betId = bookmakerOdds.best[selectedLine]._id; // Get bet_id from the 'best' bookmaker

    try {
      const response = await fetch("/api/add_bet", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${API_TOKEN}`,
        },
        body: JSON.stringify({
          user_id: "12345678", // Replace with dynamic user ID logic if available
          bet_id: betId,
        }),
      });

      const result = await response.json();

      if (response.ok) {
        console.log("Bet successfully added to the slip:", result);
      } else {
        console.error("Failed to add bet to the slip:", result);
      }
    } catch (error) {
      console.error("Error adding bet to slip:", error);
    }
  };

// Handle bookmaker click
  const handleBookmakerClick = (bookmaker) => {
    console.log(`Bookmaker clicked: ${bookmaker}`);
    addBetToSlip(); // Always add the bet from the "best" bookmaker
  };


  // Display loading message if data is not yet fetched
  if (loading) return <div>Loading...</div>;

  // Display error message if no event data is available
  if (!eventData || !eventData.home_name || !eventData.away_name) return <div>No event data available</div>;

  // Generate team image URLs or fallback to sport logo
  const homeTeamImage = eventData.home_image_id
      ? `https://assets.b365api.com/images/team/b/${eventData.home_image_id}.png`
      : `/img/sport_logos/${eventData.sport_name}_logo.png`;

  const awayTeamImage = eventData.away_image_id
      ? `https://assets.b365api.com/images/team/b/${eventData.away_image_id}.png`
      : `/img/sport_logos/${eventData.sport_name}_logo.png`;

  return (
      <div className="event-page">
        <div className="header">
          <div className="header-content">
            <img
                className="back-icon"
                alt="Back"
                src="/img/back.svg"
                onClick={onBackClick}
            />
          </div>
        </div>

        <div className="teams-section">
          <div className="team-name-wrapper">
            <div className="team-name">{eventData.home_name}</div>
          </div>
          <img className="team-avatar" alt="Home Team" src={homeTeamImage}/>
          <div className="vs-wrapper">
            <div className="vs-text">vs</div>
          </div>
          <img className="team-avatar" alt="Away Team" src={awayTeamImage}/>
          <div className="team-name-wrapper">
            <div className="team-name">{eventData.away_name}</div>
          </div>
        </div>

        {/* Team Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Team</div>
          <div className="team-options">
            <div
                className={`team-option ${selectedTeamId === eventData.home_id ? "selected" : ""}`}
                onClick={() => setSelectedTeamId(eventData.home_id)}
            >
              <div className="option-text">{eventData.home_name}</div>
            </div>
            <div
                className={`team-option ${selectedTeamId === eventData.away_id ? "selected" : ""}`}
                onClick={() => setSelectedTeamId(eventData.away_id)}
            >
              <div className="option-text">{eventData.away_name}</div>
            </div>
          </div>
        </div>

        {/* Game Selection Section */}
        <div className="selection-section">
          <div className="selection-title">Select Number of Games</div>
          <div className="game-options">
            {[5, 10, 20].map((games) => (
                <div
                    key={games}
                    className={`game-option ${selectedGames === games ? "selected" : ""}`}
                    onClick={() => setSelectedGames(games)}
                >
                  <div className="option-text">{games}</div>
                </div>
            ))}
          </div>
        </div>

        {/* Markets section */}
        <div className="selection-section">
          <div className="selection-title">Select Betting Market</div>
          <div className="market-options">
            {Object.keys(marketData).map((marketName) => (
                <div
                    key={marketName}
                    className={`market-option ${selectedMarket === marketName ? "selected" : ""}`}
                    onClick={() => handleMarketSelection(marketName)}
                >
                  <div className="option-text">{marketName}</div>
                </div>
            ))}
          </div>
        </div>

        {/* Durations section - only show if market selected and has durations */}
        {selectedMarket && marketData[selectedMarket]?.durations.length > 0 && (
            <div className="selection-section">
              <div className="selection-title">Select Duration</div>
              <div className="duration-options">
                {marketData[selectedMarket].durations.map((duration) => (
                    <div
                        key={duration}
                        className={`duration-option ${selectedDuration === duration ? "selected" : ""}`}
                        onClick={() => setSelectedDuration(duration)}
                    >
                      <div className="option-text">{duration}</div>
                    </div>
                ))}
              </div>
            </div>
        )}

        {/* Updated Line Selection Dropdown */}
        <div className="selection-section">
          <div className="selection-title">Available Lines</div>
          <select
              value={selectedLine || ''}
              onChange={e => handleLineSelection(e.target.value)}
          >
            <option value="">Select a line</option>
            {getFilteredLines().map((line, index) => (
                <option key={index} value={line}>{line}</option>
            ))}
          </select>
        </div>

        {/* Container for bar graph */}
        <div className="graph-section">
          <h3>{selectedMarket} in {selectedDuration}</h3>
          {processedData.length > 0 ? (
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                    data={processedData}
                    margin={{top: 20, right: 30, left: 20, bottom: 5}}
                >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis/>
                  <YAxis/>
                  <Tooltip
                      formatter={(value, name, props) => {
                        if (selectedLine && marketData[selectedMarket].market_type === 'binary') {
                          return [props.payload.isSuccess ? 'Success' : 'Failure', 'Result'];
                        }
                        return [value, 'Value'];
                      }}
                  />
                  <Bar
                      dataKey="value"
                      fill={(data) => data.fill}
                  />
                  {selectedLine && typeof lineValue === 'number' && marketData[selectedMarket]?.market_type !== 'binary' && (
                      <ReferenceLine
                          y={lineValue}
                          stroke="#FF9800"
                          strokeDasharray="3 3"
                          label={{ value: `${lineValue}`, position: 'right' }}
                      />
                  )}
                </BarChart>
              </ResponsiveContainer>
          ) : (
              <p>No data available for the selected options.</p>
          )}
        </div>

        {/* Display all bookmakers and odds for selected line */}
        <div className="selection-section">
          <div className="selection-title">Bookmaker Odds for Selected Line: Ad 18+</div>
          <div className="bookmaker-options">
            {bookmakerOdds && Object.keys(bookmakerOdds).length > 0 ? (
                Object.entries(bookmakerOdds).map(([bookmaker, odds]) => (
                    <div
                        key={bookmaker}
                        className="bookmaker-option"  // Add specific class for bookmaker styling
                        onClick={() => handleBookmakerClick(bookmaker)}
                    >
                      {/* Display bookmaker logo */}
                      <img
                          src={`/img/bookmakers/${bookmaker}-logo.png`}  // Example path to bookmaker logos
                          alt={`${bookmaker} logo`}
                          className="bookmaker-logo"
                      />

                      {/* Display odds */}
                      <div className="option-text">
                        {odds[selectedLine]?.cf || "N/A"}
                      </div>
                    </div>
                ))
            ) : (
                <p>No bookmaker odds available for the selected line.</p>  // Fallback message
            )}
          </div>
        </div>
      </div>
  );
};
