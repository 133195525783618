import React, { useEffect, useState } from "react";
import "./MainPage.css";

const sportIcons = {
  Soccer: "/img/sports/soccer.svg",
  Basketball: "/img/sports/basketball.svg",
  Tennis: "/img/sports/tennis.svg",
  'Ice Hockey': "/img/sports/ice-hockey.svg",
  'American Football': "/img/sports/american-football.svg",
};

const formatDateTime = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleString(undefined, {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

export const MainPage = ({ onEventSelect }) => {
  const [sports, setSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState(null);
  const [leagues, setLeagues] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);

  const API_TOKEN = process.env.REACT_APP_STATICA_API_TOKEN;

  useEffect(() => {
      window.Telegram?.WebApp?.disableVerticalSwipes()
    fetch("/api/get_sports", {
      headers: { 'Authorization': `Bearer ${API_TOKEN}` }
    })
        .then((response) => response.json())
        .then((data) => {
          setSports(data);
          if (data.length > 0) setSelectedSport(data[0]);
        })
        .catch((error) => console.error("Error fetching sports:", error));
  }, [API_TOKEN]);

  useEffect(() => {
    if (selectedSport) {
      fetch(`/api/get_relevant_leagues/${selectedSport.sport_id}`, {
        headers: { 'Authorization': `Bearer ${API_TOKEN}` }
      })
          .then((response) => response.json())
          .then((data) => setLeagues(data))
          .catch((error) => console.error("Error fetching leagues:", error));
    }
  }, [selectedSport, API_TOKEN]);

  const handleSportClick = (sport) => {
    setSelectedSport(sport);
    setCurrentEvents([]);
  };

  const handleLeagueClick = (league) => {
    const sportId = selectedSport ? selectedSport.sport_id : null;

    fetch(`/api/get_upcoming_events_by_league/${league.league_id}?sport_id=${sportId}`, {
      headers: { 'Authorization': `Bearer ${API_TOKEN}` }
    })
        .then((response) => response.json())
        .then((data) => {
          const formattedEvents = data.map(event => ({
            event_id: event.event_id,
            name: `${event.home_name} vs ${event.away_name}`,
            date: formatDateTime(event.event_time),
          }));
          setCurrentEvents(formattedEvents);
        })
        .catch((error) => console.error("Error fetching events:", error));
  };

  return (
      <div className="main-page">
        <div className="header">
          <div className="header-content">
            <div className="header-title">All Events</div>
          </div>
        </div>

        <div className="sports-list">
          {sports.map((sport, index) => (
              <div key={index} className="sport-tab" onClick={() => handleSportClick(sport)}>
                <div className="sport-icon-frame">
                  <img src={sportIcons[sport.sport_name] || "/img/sports/default-icon.svg"} alt={sport.sport_name}/>
                </div>
              </div>
          ))}
        </div>

        <div className="leagues-section">
          <div className="section-header">
            <div className="section-header-text">Select a League</div>
          </div>

          {selectedSport && leagues.length > 0 ? (
              leagues.map((league, index) => (
                  <div key={index} className="league-row">
                    <div className="league-tab" onClick={() => handleLeagueClick(league)}>
                      <div className="league-icon-frame">
                        <div className="league-icon">🏆</div>
                      </div>
                      <div className="league-name">{league.league_name}</div>
                    </div>
                  </div>
              ))
          ) : (
              <div>No leagues available for the selected sport</div>
          )}
        </div>

        <div className="events-section">
          <div className="section-header">
            <div className="section-header-text">Upcoming Events</div>
          </div>

          {currentEvents.length > 0 ? (
              currentEvents.map((event) => (
                  <div
                      key={event.event_id}
                      className="event-item"
                      onClick={() => onEventSelect(event.event_id)}
                  >
                    <div className="event-icon-frame">
                      <div className="event-icon">📅</div>
                    </div>
                    <div className="event-details">
                      <div className="event-name">{event.name}</div>
                      <div className="event-subtitle">{event.date}</div>
                    </div>
                  </div>
              ))
          ) : (
              <div className="event-item">
                <div className="event-details">
                  <div className="event-subtitle">No events available for this league.</div>
                </div>
              </div>
          )}
        </div>
      </div>
  );
};
